<template>
    <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
            <v-icon
                right
                small
                v-bind="attrs"
                v-on="on"
            >
                mdi-information-outline
            </v-icon>
        </template>
        <span v-html="getTootltipMessage"/>
    </v-tooltip>
</template>

<script>
export default {
    name: 'TTSuperadminChanges',
    data() {
        return {
            tooltipMessage: '',
        }
    },
    computed: {
        getTootltipMessage: function () {
            let message = this.$i18n.t('common.tooltips.superAdmin')

            if (this.$store.getters.getUser.is_superadmin)
                message += `<br>${ this.$i18n.t('common.tooltips.youAreSuperAdmin') }`

            return message
        },
    },
}
</script>
