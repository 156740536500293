<template>
    <div>
        <v-card-title class="pl-0 pt-7 mb-0">
            {{ title }}
            <t-t-superadmin-changes v-if="tooltip"/>
        </v-card-title>
        <v-card-subtitle
            class="pl-0"
            v-if="subtitle"
            v-html="subtitle"
        />
        <v-card-text
            class="pl-0"
            v-if="text"
            v-html="text"
        />
        <v-chip
            v-if="ssid === 15"
            :color="(passwordisset ? $store.getters.getColors.success : $store.getters.getColors.error)"
            text-color="white"
            prepend-icon="mdi-check-decagram"
        >
            <v-icon left small>
                {{ (passwordisset ? 'mdi-check-decagram' : 'mdi-alert') }}
            </v-icon>
            {{ $tc('common.labels.userCredentialsAssigned', (passwordisset ? 1 : 0)) }}
        </v-chip>
        <v-divider class="my-4"/>
    </div>
</template>

<script>
import TTSuperadminChanges from '@/components/Tooltips/TTAenderungenNurSuperAdmin.vue'

export default {
    name: 'TabTitle',
    components: { TTSuperadminChanges },
    props: {
        title: String,
        subtitle: String,
        text: String,
        tooltip: Boolean,
        ssid: Number,
        passwordisset: Boolean,
    },
}
</script>
